<template>
  <div>
    <section
      id="breadcrumb-section"
      class="breadcrumb-area breadcrumb-center"
      style="
        background: url(https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/bg/breadcrumbg.jpg)
          center center scroll;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-content">
              <div class="breadcrumb-heading"></div>
              <ol class="breadcrumb-list">
                <li>
                  <router-link to="/"><i class="fa fa-home"></i></router-link>
                </li>
                <li>A propos de nous</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="content" class="cozipress-content">
      <section
        id="about-section"
        class="about-section st-py-default shapes-section"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
              <div
                class="heading-default wow fadeInUp"
                style="visibility: visible; animation-name: fadeInUp"
              >
                <h2>A propos de <span class="text-primary">Nous</span></h2>
              </div>
            </div>
          </div>
          <div class="row g-5">
            <div
              class="col-lg-7 col-12 wow fadeInLeft"
              style="visibility: visible; animation-name: fadeInLeft"
            >
              <div
                class="about-content justify-content-lg-start justify-content-center"
              >
                <div class="about-content-wrap">
                  <p class="about-summery">
                    <span class="counter">13</span> Années expérience
                    <span></span>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-5 col-12 wow fadeInRight"
              style="visibility: visible; animation-name: fadeInRight"
            >
              <div class="about-panel">
                <div class="heading-title">
                  <h3>Tout seul on va plus vite ensemble on va plus loin</h3>
                </div>
                <ul
                  class="check-list d-flex flex-wrap align-items-center list-unstyled"
                >
                  <li>
                    <h6>
                      <span style="color: #000000"
                        >Fédérer un réseau de consultants portés qui
                        s'entraident pour le sucès de chacun est le centre de
                        nos occupations chez PORTALITE.</span
                      >
                    </h6>
                  </li>
                </ul>
                <p><span class="h3 d-inline">&nbsp;</span></p>
                <div class="about-footer">
                  <h4 class="about-sign"></h4>
                  <aside class="widget widget-contact">
                    <div class="contact-area">
                      <div class="contact-info">
                        <h6 class="title">Nejd KALAI</h6>
                        <p>Fondateur de PORTALITE</p>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg-shape5">
          <img
            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/shape5.png"
            alt="image"
          />
        </div>
        <div class="lg-shape18">
          <img
            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/shape18.png"
            alt="image"
          />
        </div>
        <div class="lg-shape7">
          <img
            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/shape7.png"
            alt="image"
          />
        </div>
        <div class="lg-shape12">
          <img
            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/shape12.png"
            alt="image"
          />
        </div>
        <div class="lg-shape9">
          <img
            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/shape9.png"
            alt="image"
          />
        </div>
      </section>
      <section id="mission-section" class="mission-section st-pb-default">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
              <div
                class="heading-default wow fadeInUp"
                style="visibility: hidden; animation-name: none"
              >
                <h2>Mission &amp; <span class="text-primary">Vision</span></h2>
              </div>
            </div>
          </div>
          <div
            class="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4 mission-contents"
          >
            <div class="col mx-md-auto">
              <div class="mission-card">
                <img
                  src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/mission/img01.jpg"
                />

                <div class="mission-content">
                  <div class="d-flex flex-wrap align-items-center">
                    <!-- <img class="mr-3" src="assets/img/studies/studiesicon-01.png" alt=""> -->
                    <i class="fa fa-pie-chart"></i>

                    <h4><a href="">Compététivité</a></h4>
                  </div>
                  <div class="media-body">
                    <p>
                      Nous essayons de vous satisfaire au maximum pour optimiser
                      vos revenues nets
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col mx-md-auto">
              <div class="mission-card">
                <img
                  src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/mission/img02.jpg"
                />

                <div class="mission-content">
                  <div class="d-flex flex-wrap align-items-center">
                    <!-- <img class="mr-3" src="assets/img/studies/studiesicon-01.png" alt=""> -->
                    <i class="fa fa-hand-peace-o"></i>

                    <h4><a href="">Approche Partenariat</a></h4>
                  </div>
                  <div class="media-body">
                    <p>
                      Chaque porté devient notre prescripteur et partenaire ,
                      nous développons ensemble pour le bien de tous
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col mx-md-auto">
              <div class="mission-card">
                <img
                  src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/mission/img03.jpg"
                />

                <div class="mission-content">
                  <div class="d-flex flex-wrap align-items-center">
                    <!-- <img class="mr-3" src="assets/img/studies/studiesicon-01.png" alt=""> -->
                    <i class="fa fa-user"></i>

                    <h4><a href="">Entraide</a></h4>
                  </div>
                  <div class="media-body">
                    <p>
                      Nous voulons avoir un réseau collaboratif qui s'entraide
                      professionallement et socialement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="working-section"
        class="working-section st-py-default"
        style="
          background-image: url('https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/about/dotted_shape.png');
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-12 mx-lg-auto mb-5 text-center">
              <div class="heading-default text-white wow fadeInUp">
                <h2>
                  Le portage <span class="text-primary">en 5 étapes</span>
                </h2>
              </div>
            </div>
          </div>
          <div
            class="row row-cols-lg-4 row-cols-md-2 row-cols-1 working-wrapper g-4 gy-md-0 gy-5"
          >
            <div class="col">
              <aside class="widget widget-contact">
                <div class="contact-area">
                  <div class="contact-icon">
                    <div class="contact-corn">
                      <i class="fa fa-qrcode"></i>
                    </div>
                  </div>

                  <div class="contact-info">
                    <h6 class="title"><a href="">La mission</a></h6>

                    <p>J'obtiens une mission au prés d'un client.</p>
                  </div>
                </div>
              </aside>
            </div>
            <div class="col">
              <aside class="widget widget-contact">
                <div class="contact-area">
                  <div class="contact-icon">
                    <div class="contact-corn">
                      <i class="fa fa-bars"></i>
                    </div>
                  </div>

                  <div class="contact-info">
                    <h6 class="title"><a href="">Le contact</a></h6>

                    <p>
                      Je prends contact avec PORTALITE qui s'en charge de
                      m'apporter un accompagnement personnalisé et adapté à mes
                      besoins.
                    </p>
                  </div>
                </div>
              </aside>
            </div>
            <div class="col">
              <aside class="widget widget-contact">
                <div class="contact-area">
                  <div class="contact-icon">
                    <div class="contact-corn">
                      <i class="fa fa-tree"></i>
                    </div>
                  </div>

                  <div class="contact-info">
                    <h6 class="title"><a href="">Le contrat</a></h6>

                    <p>
                      Je conclus mon contrat de travail CDI ou CDD et PORTALITE
                      s'en charge du contrat commercial avec mon client.
                    </p>
                  </div>
                </div>
              </aside>
            </div>
            <div class="col">
              <aside class="widget widget-contact">
                <div class="contact-area">
                  <div class="contact-icon">
                    <div class="contact-corn">
                      <i class="fa fa-download"></i>
                    </div>
                  </div>

                  <div class="contact-info">
                    <h6 class="title"><a href="">Le salaire</a></h6>

                    <p>
                      J’exécute ma mission , je remplis mon CRA ( compte rendu
                      d'activité) et je perçois mon salaire en avance chez
                      PORTALITE
                    </p>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>

      <Fun />
      <Team />
      <section
        id="cta-section"
        class="cta-section home-cta"
        style="
          background: url('https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/cta/dotted_image.png')
            no-repeat scroll center top / cover rgb(33 68 98 / 0.2);
          background-blend-mode: multiply;
        "
      >
        <div class="container">
          <div class="row wow fadeInUp">
            <div class="col-12">
              <div class="cta-wrapper text-md-left text-center">
                <div class="cta-content">
                  <div class="cta-icon-wrap mb-md-0 mb-4">
                    <div class="cta-icon"><i class="fa fa-phone"></i></div>

                    <div class="cta-img">
                      <img
                        src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/cta/avatar-1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="cta-info">
                    <h3 class="text-primary">Prenez contact avec nous</h3>
                  </div>
                </div>
                <div
                  class="cta-btn-wrap text-lg-right text-center mt-lg-0 mt-4"
                >
                  <aside class="widget widget-contact">
                    <div class="contact-area">
                      <div class="contact-info">
                        <h6 class="title">support 24/7</h6>
                        <p class="text">
                          <a href="tel:+(33) 06 42 68 67 27"
                            >+(33) 06 42 68 67 27</a
                          >
                        </p>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="testimonials-section"
        class="home-testimonial testimonials-section st-py-default shapes-section"
        style="
          background: url('https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/testimonials/testimonial_bg.jpg')
            no-repeat fixed center / cover rgba(0, 0, 0, 0.85);
          background-blend-mode: multiply;
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
              <div
                class="heading-default text-white wow fadeInUp"
                style="visibility: hidden; animation-name: none"
              >
                <h2>
                  Ils ont choisi <span class="text-primary">PORTALITE</span>
                </h2>
              </div>
            </div>
          </div>
          <div
            class="row wow fadeInUp"
            style="visibility: hidden; animation-name: none"
          >
            <div
              class="col-12 testimonials-slider owl-carousel owl-theme owl-loaded owl-drag"
            >
              <div class="owl-stage-outer">
                <div
                  class="owl-stage"
                  style="
                    transform: translate3d(-1107px, 0px, 0px);
                    transition: all 0.25s ease 0s;
                    width: 3724px;
                    padding-left: 15px;
                    padding-right: 15px;
                  "
                >
                  <div
                    class="owl-item cloned"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          Après un bechmark des sociétés de portage je me suis
                          rendu à l'évidence que Portalite propose la meilleure
                          offre. Aujourd'hui je suis très content de mon choix
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>Amine Madir</h4>

                          <span class="text-primary">Consultant Big Data</span>
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          Je n'avais ni le temps ni la motivation de monter une
                          structure pour mon activité. J'ai opté pour le portage
                          comme solution très optimale et mon choix est porté
                          sur Portalite pour la qualité d'écoute et des
                          explications ainsi que le rapport qualité prix
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>Amandine Lecerf</h4>

                          <span class="text-primary"
                            >Consultante en stratégie
                          </span>
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          la relation humaine qu'apporte Portalite fait en sorte
                          qu'on ne peut qu'à adhérer
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>Marwen Rhif</h4>

                          <span class="text-primary"
                            >Consultant en recrutement specialisé</span
                          >
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item active"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          Portalite est une petite structure très dynamique qui
                          a su m'accompagner rapidement dans mon activité. Je
                          suis très satisfait.
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>André Pavet</h4>

                          <span class="text-primary"
                            >Chef de projet Télécom</span
                          >
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item active"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          Après un bechmark des sociétés de portage je me suis
                          rendu à l'évidence que Portalite propose la meilleure
                          offre. Aujourd'hui je suis très content de mon choix
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>Amine Madir</h4>

                          <span class="text-primary">Consultant Big Data</span>
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item active"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          Je n'avais ni le temps ni la motivation de monter une
                          structure pour mon activité. J'ai opté pour le portage
                          comme solution très optimale et mon choix est porté
                          sur Portalite pour la qualité d'écoute et des
                          explications ainsi que le rapport qualité prix
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>Amandine Lecerf</h4>

                          <span class="text-primary"
                            >Consultante en stratégie
                          </span>
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          la relation humaine qu'apporte Portalite fait en sorte
                          qu'on ne peut qu'à adhérer
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>Marwen Rhif</h4>

                          <span class="text-primary"
                            >Consultant en recrutement specialisé</span
                          >
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          Portalite est une petite structure très dynamique qui
                          a su m'accompagner rapidement dans mon activité. Je
                          suis très satisfait.
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>André Pavet</h4>

                          <span class="text-primary"
                            >Chef de projet Télécom</span
                          >
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          Après un bechmark des sociétés de portage je me suis
                          rendu à l'évidence que Portalite propose la meilleure
                          offre. Aujourd'hui je suis très content de mon choix
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>Amine Madir</h4>

                          <span class="text-primary">Consultant Big Data</span>
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style="width: 339.333px; margin-right: 30px"
                  >
                    <div class="testimonials-item shapes-section">
                      <div class="testimonials-content">
                        <p>
                          Je n'avais ni le temps ni la motivation de monter une
                          structure pour mon activité. J'ai opté pour le portage
                          comme solution très optimale et mon choix est porté
                          sur Portalite pour la qualité d'écoute et des
                          explications ainsi que le rapport qualité prix
                        </p>

                        <div class="rating-star">
                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>

                          <a href=""><i class="fa fa-star"></i></a>
                        </div>
                        <div class="testimonials-title">
                          <h4>Amandine Lecerf</h4>

                          <span class="text-primary"
                            >Consultante en stratégie
                          </span>
                        </div>
                      </div>
                      <div class="testimonials-client"></div>
                      <div class="lg-shape32">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape3.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape33">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape4.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape34">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape5.png"
                          alt="image"
                        />
                      </div>
                      <div class="lg-shape35">
                        <img
                          src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape6.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-nav disabled">
                <button type="button" role="presentation" class="owl-prev">
                  <span aria-label="Previous">‹</span></button
                ><button type="button" role="presentation" class="owl-next">
                  <span aria-label="Next">›</span>
                </button>
              </div>
              <div class="owl-dots">
                <button role="button" class="owl-dot active">
                  <span></span></button
                ><button role="button" class="owl-dot"><span></span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="lg-shape21">
          <img
            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape1.png"
            alt="image"
          />
        </div>
        <div class="lg-shape21bottom">
          <img
            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/clipArt/testimonials/shape2.png"
            alt="image"
          />
        </div>
      </section>
      <section
        id="sponsors-section"
        class="sponsors-section st-py-default sponsor-home"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-12 mx-lg-auto mb-5 text-center">
              <div
                class="heading-default wow fadeInUp"
                style="visibility: hidden; animation-name: none"
              >
                <h2>Nos<span class="text-primary">Partenaires</span></h2>
              </div>
            </div>
          </div>
          <div
            class="row row-cols-1 row-cols-lg-5 row-cols-md-2 sponsors-area g-0 wow fadeInUp"
            style="visibility: hidden; animation-name: none"
          >
            <div
              class="col sponsors-carousel owl-carousel owl-theme owl-loaded owl-drag"
            >
              <div class="owl-stage-outer owl-height" style="height: 190.594px">
                <div
                  class="owl-stage"
                  style="
                    transform: translate3d(-1994px, 0px, 0px);
                    transition: all 0.25s ease 0s;
                    width: 3546px;
                  "
                >
                  <div class="owl-item cloned" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/axa.jpg"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/quonto1.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/hiscox.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/soge.jpg"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/hoggo.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/steelia.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/axa.jpg"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/quonto1.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/hiscox.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item active" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/soge.jpg"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item active" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/hoggo.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned active" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/steelia.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned active" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/axa.jpg"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned active" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/quonto1.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/hiscox.png"
                        /></a>
                      </div>
                    </article>
                  </div>
                  <div class="owl-item cloned" style="width: 221.6px">
                    <article class="sponsors-items">
                      <div class="sponsors-img">
                        <a href=""
                          ><img
                            src="https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/sponsors/soge.jpg"
                        /></a>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div class="owl-nav disabled">
                <button type="button" role="presentation" class="owl-prev">
                  <span aria-label="Previous">‹</span></button
                ><button type="button" role="presentation" class="owl-next">
                  <span aria-label="Next">›</span>
                </button>
              </div>
              <div class="owl-dots">
                <button role="button" class="owl-dot active">
                  <span></span></button
                ><button role="button" class="owl-dot"><span></span></button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--===// Start: Footer
    =================================-->
    </div>
  </div>
</template>

<script>
import Fun from "../components/Fun.vue";
import Team from "../components/Team.vue";
export default {
  components: { Fun, Team },
};
</script>

<style></style>
