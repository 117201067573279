<template>
  <div>
    <section
      id="breadcrumb-section"
      class="breadcrumb-area breadcrumb-center"
      style="
        background: url(/wp-content/themes/cozipress-pro/assets/images/bg/breadcrumbg.jpg)
          center center scroll;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-content">
              <div class="breadcrumb-heading"></div>
              <ol class="breadcrumb-list">
                <li>
                  <router-link to="/"><i class="fa fa-home"></i></router-link>
                </li>
                <li>Contact</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="content" class="cozipress-content">
      <section id="post-section" class="post-section st-py-default">
        <div class="container">
          <div
            class="row row-cols-1 gy-5 wow fadeInUp"
            style="visibility: visible; animation-name: fadeInUp"
          >
            <div class="col">
              <article class="post-items">
                <div class="post-content">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="2"
                    class="elementor elementor-2"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-section-wrap">
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-8f83f50 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="8f83f50"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1cadae0"
                            data-id="1cadae0"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-130e151 elementor-widget elementor-widget-wpforms"
                                data-id="130e151"
                                data-element_type="widget"
                                data-widget_type="wpforms.default"
                              >
                                <div class="elementor-widget-container">
                                  <div
                                    class="wpforms-container wpforms-container-full"
                                    id="wpforms-359"
                                  >
                                    <form
                                      class="row g-3 needs-validation"
                                      novalidate
                                      @submit.prevent="sendcontact"
                                    >
                                      <div class="col-md-12 col-sm-12">
                                        <label
                                          for="validationCustom01"
                                          class="form-label"
                                          >Prénom</label
                                        >

                                        <input
                                          type="text"
                                          class="form-control"
                                          id="validationCustom01"
                                          v-model="name"
                                          required
                                        />
                                        <div class="invalid-feedback">
                                          Veuillez tapez votre nom et prénom.
                                        </div>
                                      </div>

                                      <div class="col-md-12 col-sm-12">
                                        <label
                                          for="validationCustom03"
                                          class="form-label"
                                          >Téléphone</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="validationCustom03"
                                          v-model="phone"
                                          required
                                        />
                                        <div class="invalid-feedback">
                                          Veuillez tapez votre téléphone.
                                        </div>
                                      </div>
                                      <div class="col-md-12 col-sm-12">
                                        <label
                                          for="validationCustom04"
                                          class="form-label"
                                          >Commentaire ou message</label
                                        >
                                        <textarea
                                          class="form-text"
                                          id="validationCustom04"
                                          required
                                          v-model="message"
                                        >
                                        </textarea>
                                        <div class="invalid-feedback">
                                          Veuillez tapez votre message.
                                        </div>
                                      </div>

                                      <div class="col-12">
                                        <button
                                          class="btn btn-primary"
                                          type="submit"
                                        >
                                          Submit form
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                  <!-- .wpforms-container -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <!--===// Start: Footer
    =================================-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      changeBack: false,

      name: "",

      phone: "",
      message: "",
    };
  },
  methods: {
    sendcontact() {
      // this.name = document.getElementById("name").value;
      // this.lastname = document.getElementById("lastname").value;
      // this.email = document.getElementById("email").value;
      // this.phone = document.getElementById("phone").value;
      // this.message = document.getElementById("message").value;
      // this.identity = document.querySelector(
      //   "input[name=identity]:checked"
      // ).value;
      // let data = {
      //   name: this.name,
      //   lastname: this.lastname,
      //   email: this.email,
      //   phone: this.phone,
      //   message: this.message,
      //   identity: this.identity,
      // };
      // console.log(data);
      var forms = document.querySelectorAll(".needs-validation");
      let check = true;
      // Loop over them and prevent submission
      Array.prototype.slice.call(forms).forEach(function (form) {
        if (!form.checkValidity()) {
          check = false;
          return false;
        }
      });

      let data = {
        name: this.name,

        phone: this.phone,
        message: this.message,
      };
      console.log(data);
      if (check) {
        this.$axios.post("savecontacted", data).then((res) => {
          this.$swal({
            text: "Message bien enregistré",
            icon: "success",

            confirmButtonColor: "#3085d6",
          }).then(() => {
            this.$router.push("/");
          });
        });
      }
    },
  },
};
</script>

<style></style>
