<template>
  <div :key="item.id">
    <section
      id="breadcrumb-section"
      class="breadcrumb-area breadcrumb-center"
      style="
        background: url(https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/bg/breadcrumbg.jpg)
          center center scroll;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-content">
              <div class="breadcrumb-heading"></div>
              <ol class="breadcrumb-list">
                <li>
                  <router-link to="/"><i class="fa fa-home"></i></router-link>
                </li>
                <li v-if="!loading">{{ content.title }}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="content" class="cozipress-content">
      <section id="post-section" class="post-section st-py-default">
        <div class="container">
          <div
            class="row row-cols-1 gy-5 wow fadeInUp"
            style="visibility: visible; animation-name: fadeInUp"
          >
            <div class="col">
              <article class="post-items">
                <div class="post-content">
                  <p v-if="!loading">{{ content.description }}</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <!--===// Start: Footer
    =================================-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "content",
      id: null,
      content: null,
      loading: true,
    };
  },
  methods: {
    getContent() {
      this.loading = true;
      this.$axios.get("service/" + this.id).then((res) => {
        this.content = res.data.data;
        console.log("content", this.content);
        this.loading = false;
      });
    },
  },
  beforeCreate() {
    this.id = this.$route.query.id;
    console.log(this.id);
  },
  mounted() {
    this.getContent();
  },
};
</script>

<style></style>
