<template>
  <div>
    <section
      id="breadcrumb-section"
      class="breadcrumb-area breadcrumb-center"
      style="
        background: url(https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/bg/breadcrumbg.jpg)
          center center scroll;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-content">
              <div class="breadcrumb-heading"></div>
              <ol class="breadcrumb-list">
                <li>
                  <router-link to="/"><i class="fa fa-home"></i> </router-link>
                </li>
                <li v-if="!loading && content.name">{{ content.name }}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="content" class="">
      <section id="post-section" class="post-section st-py-default">
        <div class="container">
          <div
            class="row row-cols-1 gy-5 wow"
            style="visibility: visible; animation-name: fadeInUp"
          >
            <div class="col">
              <article class="post-items">
                <div class="post-content" v-if="!loading">
                  <p v-html="article"></p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <!--===// Start: Footer
    =================================-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: {},
      article: null,
      name: null,
      content: null,
      loading: true,
    };
  },

  methods: {
    getContent() {
      this.loading = true;

      this.$axios.get("navarticle/" + this.name.item).then((res) => {
        this.content = res.data.data;
        if (this.content.article && this.content.article.article.length > 0)
          this.article = this.content.article.article;

        this.loading = false;
      });
    },
  },
  //   computed: {
  //     urlname: function () {
  //       return this.getContent();
  //     },
  //   },
  //   watch: {
  //     "$router.params": {
  //       handler(newValue) {
  //         this.getContent();
  //       },
  //       immediate: true,
  //     },
  //   },
  //   update() {
  //     this.name = this.$route.params;
  //
  //     this.getContent();
  //   },
  created() {
    this.name = this.$route.params;
    console.log("first", this.name);
    this.getContent();
  },
  watch: {
    $route(to, from) {
      this.name = to.params;
      this.getContent();
    },
  },
  // beforeUpdate() {
  //   this.loading = true;
  //   this.name = this.$route.params;
  // },

  // beforeRouteUpdate(to, from, next) {
  //   // Call the API query method when the URL changes
  //   this.loading = true;
  //   this.name = this.$route.params;
  //   console.log("here", this.name);
  //   this.getContent();
  //   next();
  // },
};
</script>

<style></style>
