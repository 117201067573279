<template>
  <div>
    <section
      id="breadcrumb-section"
      class="breadcrumb-area breadcrumb-center"
      style="
        background: url(../wp-content/themes/cozipress-pro/assets/images/bg/breadcrumbg.jpg)
          center center scroll;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-content">
              <div class="breadcrumb-heading"></div>
              <ol class="breadcrumb-list">
                <li>
                  <router-link to="/"><i class="fa fa-home"></i></router-link>
                </li>
                <li>Simulation de la rémunération</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="post-section" class="post-section st-py-default">
      <div class="container">
        <div
          class="row row-cols-1 gy-5 wow"
          style="visibility: visible; animation-name: fadeInUp"
        >
          <div class="col">
            <article class="post-items">
              <div class="post-content">
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id="102"
                  class="elementor elementor-102"
                  data-elementor-settings="[]"
                >
                  <div class="elementor-section-wrap">
                    <section
                      class="elementor-section elementor-top-section elementor-element elementor-element-f43ec83 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="f43ec83"
                      data-element_type="section"
                    >
                      <div
                        class="elementor-container elementor-column-gap-default"
                      >
                        <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3e6ee6c"
                          data-id="3e6ee6c"
                          data-element_type="column"
                        >
                          <div
                            class="elementor-widget-wrap elementor-element-populated"
                          >
                            <div
                              class="elementor-element elementor-element-2672c6f elementor-widget__width-inherit elementor-widget elementor-widget-calculated-fields-form"
                              data-id="2672c6f"
                              data-element_type="widget"
                              data-widget_type="calculated-fields-form.default"
                            >
                              <div class="elementor-widget-container">
                                <form
                                  name="cp_calculatedfieldsf_pform_1"
                                  id="cp_calculatedfieldsf_pform_1"
                                  method="post"
                                  enctype="multipart/form-data"
                                  class="cp_cff_minimalist"
                                  novalidate="novalidate"
                                  data-evalequations="0"
                                  data-evalequationsevent="2"
                                  autocomplete="on"
                                >
                                  <div id="fbuilder">
                                    <div id="fbuilder_1">
                                      <div id="formheader_1"></div>
                                      <div
                                        id="fieldlist_1"
                                        class="right_aligned"
                                      >
                                        <div
                                          class="fform"
                                          id="field"
                                          style="background-color: #00aaa8"
                                        >
                                          <h2 style="color: white">
                                            Simulateur
                                          </h2>
                                          <span></span>
                                        </div>
                                        <div class="pb0 pbreak" page="0">
                                          <div
                                            class="fields fieldname16_1 cff-textarea-field"
                                            id="field_1-0"
                                          >
                                            <label for="fieldname16_1"></label>
                                            <div class="dfield">
                                              <textarea
                                                aria-label=""
                                                rows="1"
                                                id="fieldname16_1"
                                                name="fieldname16_1"
                                                class="field large valid"
                                                readonly=""
                                                aria-invalid="false"
                                              >
                                                Pour avoir une idée indicative
                                                rapide sur mon salaire net
                                              </textarea>
                                              <span class="uh"></span>
                                            </div>
                                            <div class="clearer"></div>
                                          </div>
                                          <div
                                            class="fields nbrejour cff-number-field"
                                            id="field_1-1"
                                          >
                                            <label for="nbrejour"
                                              >Nombre de jours
                                              travaillés/mois<span class="r"
                                                >*</span
                                              ></label
                                            >
                                            <div class="dfield">
                                              <input
                                                aria-label="Nombre de jours travaillés/mois"
                                                id="nbrejour"
                                                name="nbrejour"
                                                min="0"
                                                max="23"
                                                class="field digits small required simul"
                                                type="number"
                                                value=""
                                                v-model="nbrejour"
                                                placeholder=""
                                                @change="
                                                  nbrejour > 23 || nbrejour <= 0
                                                    ? (nbrejour = 23)
                                                    : nbrejour
                                                "
                                              /><span class="uh"></span>
                                            </div>
                                            <div class="clearer"></div>
                                          </div>
                                          <div
                                            class="fields tjm cff-number-field"
                                            id="field_1-2"
                                          >
                                            <label for="tjm"
                                              >TJM facturé HT<span class="r"
                                                >*</span
                                              ></label
                                            >
                                            <div class="dfield">
                                              <input
                                                aria-label="TJM facturé HT"
                                                id="tjm"
                                                name="tjm"
                                                v-model="tjm"
                                                min="0"
                                                max="5000"
                                                class="field digits small required simul"
                                                type="number"
                                                value=""
                                              /><span class="uh"></span>
                                            </div>
                                            <div class="clearer"></div>
                                          </div>

                                          <!----  --->
                                          <div
                                            class="fields tjm cff-number-field"
                                            id="field_1-2"
                                          >
                                            <label for=""
                                              >Frais kilométriques</label
                                            >
                                            <label class="switch">
                                              <input
                                                type="checkbox"
                                                v-model="motorisation"
                                                style="background-color: green"
                                              />
                                              <span
                                                class="checkslider round"
                                              ></span>
                                            </label>
                                            <div class="clearer"></div>
                                          </div>
                                          <div
                                            class="fields tjm cff-number-field"
                                            v-if="motorisation == true"
                                          >
                                            <label for="total_km">
                                              Distance Domicile- Lieu de mission
                                              ( km)</label
                                            >
                                            <div class="dfield">
                                              <input
                                                aria-label="Total kilometrique"
                                                id="total_km"
                                                name="total_km"
                                                min="0"
                                                max="50"
                                                v-model="distance"
                                                class="field digits small simul"
                                                type="number"
                                                value=""
                                                placeholder=""
                                              /><span class="uh"></span>
                                            </div>
                                            <div class="clearer"></div>
                                            <label for="nbre_cv"
                                              >Nombre Chevaux</label
                                            >
                                            <div class="dfield">
                                              <input
                                                aria-label="Frais Professionels"
                                                id="nbre_cv"
                                                name="nbre_cv"
                                                min="3"
                                                class="field digits small simul"
                                                type="number"
                                                v-model="nbre_cv"
                                                placeholder=""
                                              /><span class="uh"></span>
                                            </div>
                                            <div class="clearer"></div>
                                          </div>
                                          <!---- 
 --->

                                          <div
                                            class="fields fieldname4_1 cff-button-field"
                                            id="field_1-4"
                                          >
                                            <input
                                              id="fieldname4_1"
                                              type="button"
                                              value="Calculer"
                                              class="field calculate-button"
                                              @click="calcul"
                                            /><span class="uh"></span>
                                            <div class="clearer"></div>
                                          </div>
                                          <div v-if="chiffremensuel">
                                            <div
                                              class="fields chiffremensuel cff-calculated-field"
                                              id="field_1-5"
                                              style=""
                                            >
                                              <label for="chiffremensuel"
                                                >Chiffre d'affaire
                                                mensuel</label
                                              >
                                              <div class="dfield">
                                                <input
                                                  aria-label="Chiffre d'affaire mensuel"
                                                  id="chiffremensuel"
                                                  name="chiffremensuel"
                                                  readonly=""
                                                  class="codepeoplecalculatedfield field medium"
                                                  type="text"
                                                  :value="chiffremensuel"
                                                  dep=""
                                                  notdep=""
                                                /><span class="uh"></span>
                                              </div>
                                              <div class="clearer"></div>
                                            </div>
                                            <div
                                              class="fields fraisgestion cff-calculated-field"
                                              id="field_1-6"
                                              style=""
                                            >
                                              <label for="fraisgestion"
                                                >Frais de gestion</label
                                              >
                                              <div class="dfield">
                                                <input
                                                  aria-label="Frais de gestion"
                                                  id="fraisgestion"
                                                  name="fraisgestion"
                                                  readonly=""
                                                  class="codepeoplecalculatedfield field medium"
                                                  type="text"
                                                  :value="fraisgestion"
                                                  placeholder=""
                                                  dep=""
                                                  notdep=""
                                                /><span class="uh"></span>
                                              </div>
                                              <div class="clearer"></div>
                                            </div>

                                            <div
                                              class="fields fraisgestion cff-calculated-field"
                                              id="field_1-6"
                                              style=""
                                            >
                                              <label for="fraisgestion"
                                                >Frais professionels</label
                                              >
                                              <div class="dfield">
                                                <input
                                                  aria-label="Frais de gestion"
                                                  id="fraisgestion"
                                                  name="fraisgestion"
                                                  readonly=""
                                                  class="codepeoplecalculatedfield field medium"
                                                  type="text"
                                                  :value="fraispro"
                                                  placeholder=""
                                                  dep=""
                                                  notdep=""
                                                /><span class="uh"></span>
                                              </div>
                                              <div class="clearer"></div>
                                            </div>
                                            <!----env_salariale --->
                                            <div
                                              class="fields fraisgestion cff-calculated-field"
                                              id="field_1-6"
                                              style=""
                                            >
                                              <label for="env_salariale"
                                                >Enveloppe salariale</label
                                              >
                                              <div class="dfield">
                                                <input
                                                  aria-label="Enveloppe salariale"
                                                  id="env_salariale"
                                                  name="env_salariale"
                                                  readonly=""
                                                  class="codepeoplecalculatedfield field medium"
                                                  type="text"
                                                  :value="env_salariale"
                                                  placeholder=""
                                                  dep=""
                                                  notdep=""
                                                /><span class="uh"></span>
                                              </div>
                                              <div class="clearer"></div>
                                            </div>

                                            <!----env_salariale --->
                                            <div
                                              class="fields chargepatronal cff-calculated-field"
                                              id="field_1-7"
                                              style=""
                                            >
                                              <label for="chargepatronal"
                                                >Charges Patronales</label
                                              >
                                              <div class="dfield">
                                                <input
                                                  aria-label="Charges Patronales"
                                                  id="chargepatronal"
                                                  name="chargepatronal"
                                                  readonly=""
                                                  class="codepeoplecalculatedfield field medium"
                                                  type="text"
                                                  :value="chargepatronal"
                                                  dep=""
                                                  notdep=""
                                                /><span class="uh"></span>
                                              </div>
                                              <div class="clearer"></div>
                                            </div>
                                            <div
                                              class="fields chargesocial cff-calculated-field"
                                              id="field_1-8"
                                              style=""
                                            >
                                              <label for="chargesocial"
                                                >Charges Sociales</label
                                              >
                                              <div class="dfield">
                                                <input
                                                  aria-label="Charges Sociales"
                                                  id="chargesocial"
                                                  name="chargesocial"
                                                  readonly=""
                                                  class="codepeoplecalculatedfield field medium"
                                                  type="text"
                                                  :value="chargesocial"
                                                  dep=""
                                                  notdep=""
                                                /><span class="uh"></span>
                                              </div>
                                              <div class="clearer"></div>
                                            </div>
                                            <div
                                              class="fields totalverse cff-calculated-field"
                                              id="field_1-9"
                                              style=""
                                            >
                                              <label for="totalverse"
                                                >Total versé éstimé</label
                                              >
                                              <div class="dfield">
                                                <input
                                                  aria-label="Total versé éstimé"
                                                  id="totalverse"
                                                  name="totalverse"
                                                  readonly=""
                                                  class="codepeoplecalculatedfield field medium styled"
                                                  type="text"
                                                  :value="total_estime"
                                                  placeholder=""
                                                  dep=""
                                                  notdep=""
                                                /><span class="uh"></span>
                                              </div>
                                              <div class="clearer"></div>
                                            </div>
                                            <div
                                              class="fields salairebrutannuel cff-calculated-field"
                                              id="field_1-10"
                                              style=""
                                            >
                                              <label for="salairebrutannuel"
                                                >Salaire Brut Annuel
                                                équivalent</label
                                              >
                                              <div class="dfield">
                                                <input
                                                  aria-label="Salaire Brut Annuel équivalent"
                                                  id="salairebrutannuel"
                                                  name="salairebrutannuel"
                                                  readonly=""
                                                  class="codepeoplecalculatedfield field medium styled"
                                                  type="text"
                                                  :value="salairebrutannuel"
                                                  placeholder=""
                                                  dep=""
                                                  notdep=""
                                                /><span class="uh"></span>
                                              </div>
                                              <div class="clearer"></div>
                                            </div>
                                            <div
                                              class="fields fieldname15_1 cff-button-field"
                                              id="field_1-11"
                                            >
                                              <input
                                                v-if="total_estime"
                                                id="fieldname15_1"
                                                v-model="fieldname15_1"
                                                type="button"
                                                value="Récévoir une simulation detaillée"
                                                class="field"
                                                @click="sendMail()"
                                              /><span class="uh"></span>
                                              <div class="clearer"></div>
                                            </div>
                                          </div>

                                          <div
                                            class="fields fieldname8_1 cff-html-field"
                                            id="field_1-12"
                                          >
                                            <div
                                              id="fieldname8_1"
                                              class="dfield"
                                            >
                                              <!--unique--><!--end_unique-->
                                            </div>
                                            <div class="clearer"></div>
                                          </div>
                                          <div
                                            class="fields fieldname9_1 cff-calculated-field"
                                            id="field_1-13"
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              border: 0;
                                              opacity: 0;
                                              width: 0;
                                              height: 0;
                                              overflow: hidden;
                                            "
                                          >
                                            <label for="fieldname9_1"
                                              >Untitled</label
                                            >
                                            <div class="dfield">
                                              <input
                                                aria-label="Untitled"
                                                id="fieldname9_1"
                                                name="fieldname9_1"
                                                v-model="fieldname9_1"
                                                readonly=""
                                                class="codepeoplecalculatedfield field medium"
                                                type="hidden"
                                                value=""
                                                dep=""
                                                notdep=""
                                              />
                                            </div>
                                            <div class="clearer"></div>
                                          </div>
                                          <div
                                            class="fields fieldname10_1 cff-html-field"
                                            id="field_1-14"
                                          >
                                            <div
                                              id="fieldname10_1"
                                              class="dfield"
                                            >
                                              <div
                                                id="chart"
                                                class="chartsection"
                                                v-if="chiffremensuel"
                                              >
                                                <div class="apexsection">
                                                  <apexchart
                                                    type="donut"
                                                    width="60%"
                                                    :options="chartOptions"
                                                    :labels="labels"
                                                    :series="series"
                                                    v-if="series.length > 0"
                                                  ></apexchart>
                                                </div>
                                                <strong
                                                  >Total versé éstimé :
                                                  {{ total_estime }}</strong
                                                >
                                              </div>
                                              <div class="noticesection">
                                                <hr />
                                                <button
                                                  class="btn btn-rounded"
                                                  style="
                                                    color: white;
                                                    background-color: #00aaa8;
                                                  "
                                                  @click.prevent="
                                                    showSimulationDetail()
                                                  "
                                                >
                                                  Comprendre ma simulation de
                                                  salaire
                                                </button>
                                              </div>
                                            </div>
                                            <div class="clearer"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="clearer"></div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      changeBack: false,

      series: [],
      labels: {
        show: true,
        name: { name: "test" },
      },
      chartOptions: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        chart: {
          width: 180,
          type: "pie",
        },
        dataLabels: {
          enabled: true,
          dataLabels: {
            enabled: true,

            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                  },
                },
              },
            },
          },
        },
        total: {
          show: true,
        },
        labels: ["Revenues", "Charges"],
        colors: ["#2CBBBB", "#a7aaad"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      cp_calculatedfieldsf_pform_psequence: null,
      motorisation: false,
      cp_calculatedfieldsf_id: null,
      fieldname16_1: null,
      frais_gestion: 7,
      nbrejour: null,
      tjm: null,
      distance: 10,
      t_km_parcour: 0,
      nbre_cv: 3,
      notice: null,
      taux_charge: "1.55",
      reserve_f: null, //
      ind_fin_contrat_net: null,
      ind_fin_contrat_brut: null,
      ind_kilo: null,
      enveloppe_salarial: null,
      ind_repas: null,
      frais_pro: null,
      fraispro: null,
      env_salariale: null,
      chiffremensuel: null,
      fraisgestion: null,
      chargepatronal: null,
      chargesocial: null,
      totalverse: null,
      salairebrut: null,
      salairebrutannuel: null,
      s_brut_and_reserve: null,
      salaire_net: null,
      restitution_reserve_finance: null,
      total_estime: null,
      fieldname15_1: "Recevoir une simulation détaillée",
      fieldname9_1: null,
      abon_public: 75,
    };
  },
  methods: {
    showSimulationDetail() {
      this.$swal({
        icon: "info",

        html: `
        <h3>Comprendre ma simulation de salaire</h3>
        <p>  
        ${this.notice}</p>`,
      });
    },
    calcul() {
      this.chiffremensuel = parseFloat(this.nbrejour) * parseFloat(this.tjm);
      this.fraisgestion =
        (this.frais_gestion * parseFloat(this.chiffremensuel)) / 100;
      if (this.motorisation == true) {
        this.t_km_parcour =
          parseFloat(this.distance) * 2 * parseFloat(this.nbrejour);
        console.log(this.t_km_parcour);
        if (this.nbre_cv == 3)
          this.ind_kilo = parseFloat(this.t_km_parcour) * 0.456;
        if (this.nbre_cv == 4)
          this.ind_kilo = parseFloat(this.t_km_parcour) * 0.523;
        if (this.nbre_cv == 5)
          this.ind_kilo = parseFloat(this.t_km_parcour) * 0.548;
        if (this.nbre_cv == 3)
          this.ind_kilo = parseFloat(this.t_km_parcour) * 0.456;
        if (this.nbre_cv > 6)
          this.ind_kilo = parseFloat(this.t_km_parcour) * 0.601;
      } else {
        this.ind_kilo = this.abon_public;
      }
      console.log(this.ind_kilo);
      console.log("in repas old", this.ind_repas);
      this.ind_repas = parseFloat(this.nbrejour) * parseFloat(this.ind_repas);
      console.log("in repas new", this.ind_repas);
      console.log("frais perso old", this.fraispro);
      this.fraispro = parseFloat(this.ind_kilo) + parseFloat(this.ind_repas);
      console.log("frais perso new", this.fraispro);

      this.env_salariale =
        parseFloat(this.chiffremensuel) -
        parseFloat(this.fraispro) -
        parseFloat(this.fraisgestion);
      this.salairebrut =
        parseFloat(this.env_salariale) / parseFloat(this.taux_charge);
      this.ind_fin_contrat_net = parseFloat(this.salairebrut) / 3 / 12;
      this.ind_fin_contrat_brut = parseFloat(this.ind_fin_contrat_net) * 1.2;
      this.reserve_f =
        parseFloat(this.salairebrut) * 0.1 - this.ind_fin_contrat_brut;

      this.chargepatronal =
        parseFloat(this.salairebrut) * (parseFloat(this.taux_charge) - 1.1);
      this.chargesocial = parseFloat(this.salairebrut) * 0.22;
      this.s_brut_and_reserve = this.salairebrut + this.reserve_f / 1.45;
      this.salaire_net = this.salairebrut * 0.78;
      this.restitution_reserve_finance =
        (this.reserve_f / (this.taux_charge - 0.1)) * 0.78;
      this.total_estime =
        parseFloat(this.salaire_net) +
        parseFloat(this.restitution_reserve_finance) +
        parseFloat(this.ind_fin_contrat_net) +
        parseFloat(this.fraispro);
      this.salairebrutannuel = (this.total_estime * 12) / 0.78;
      ////

      this.frais_gestion = Number(this.frais_gestion).toFixed(1);

      this.taux_charge = Number(this.taux_charge).toFixed(1);
      this.reserve_f = Number(this.reserve_f).toFixed(1);
      this.ind_fin_contrat_net = Number(this.ind_fin_contrat_net).toFixed(1);
      this.ind_fin_contrat_brut = Number(this.ind_fin_contrat_brut).toFixed(1);
      this.ind_kilo = Number(this.ind_kilo).toFixed(1);
      this.ind_repas = Number(this.ind_repas).toFixed(1);
      this.frais_pro = Number(this.frais_pro).toFixed(1);
      this.fraispro = Number(this.fraispro).toFixed(1);
      this.env_salariale = Number(this.env_salariale).toFixed(1);
      this.chiffremensuel = Number(this.chiffremensuel).toFixed(1);
      this.fraisgestion = Number(this.fraisgestion).toFixed(1);
      this.chargepatronal = Number(this.chargepatronal).toFixed(1);
      this.chargesocial = Number(this.chargesocial).toFixed(1);
      this.totalverse = Number(this.totalverse).toFixed(1);
      this.salairebrut = Number(this.salairebrut).toFixed(1);
      this.salairebrutannuel = Number(this.salairebrutannuel).toFixed(1);
      this.s_brut_and_reserve = Number(this.s_brut_and_reserve).toFixed(1);
      this.salaire_net = Number(this.salaire_net).toFixed(1);
      this.restitution_reserve_finance = Number(
        this.restitution_reserve_finance
      ).toFixed(2);
      this.total_estime = Number(this.total_estime).toFixed(2);
      console.log("teete", this.frais_gestion);
      this.series = [
        parseFloat(this.total_estime),

        this.chiffremensuel - parseFloat(this.total_estime),
      ];
      this.getConfiguration();
    },
    sendMail() {
      this.$swal({
        title: "Recevoir une simulation détaillée",
        html: '<form class="row g-3 needs-validation" novalidate><input type="text" placeholder="Tapez votre email" id="email" required/><br><input type="text" placeholder="Tapez votre téléphone si vous voulez être contacté" id="phone"/> </form>',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, envoyer",
        preConfirm: () => {
          let email = document.getElementById("email").value;
          if (email == "") {
            this.$swal.showValidationMessage(
              `Vous avez oubliez de tapez votre email`
            );
          } else if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) == false
          ) {
            this.$swal.showValidationMessage(`Merci de tapez un email valide`);
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (
          result.isConfirmed &&
          document.getElementById("email").value != ""
        ) {
          let email = document.getElementById("email").value;
          let phone = document.getElementById("phone").value;
          let data = {
            frais_gestion: this.frais_gestion,
            nbrejour: this.nbrejour,
            tjm: this.tjm,
            distance: this.distance,
            t_km_parcour: this.t_km_parcour,
            nbre_cv: this.nbre_cv,
            taux_charge: this.taux_charge,
            reserve_f: this.reserve_f,
            ind_fin_contrat_net: this.ind_fin_contrat_net,
            ind_fin_contrat_brut: this.ind_fin_contrat_brut,
            ind_kilo: this.ind_kilo,
            ind_repas: this.ind_repas,
            frais_pro: this.frais_pro,
            fraispro: this.fraispro,
            env_salariale: this.env_salariale,
            chiffremensuel: this.chiffremensuel,
            fraisgestion: this.fraisgestion,
            chargepatronal: this.chargepatronal,
            chargesocial: this.chargesocial,
            totalverse: this.totalverse,
            salairebrut: this.salairebrut,
            salairebrutannuel: this.salairebrutannuel,
            s_brut_and_reserve: this.s_brut_and_reserve,
            salaire_net: this.salaire_net,
            restitution_reserve_finance: this.restitution_reserve_finance,
            total_estime: this.total_estime,
          };
          axios
            .post("/sendmail/" + email, { phone: phone, data: data })
            .then(() => this.reload());

          this.$swal("Bien envoyé", "Verifier votre boite mail.", "success");
        }
      });
    },
    getConfiguration() {
      axios.get("allconfiguration").then((res) => {
        this.frais_gestion = res.data.data[0].frais_gestion;
        this.ind_repas = res.data.data[0].ind_repas;
        this.taux_charge = res.data.data[0].taux_charge;
        this.abon_public = res.data.data[0].abon_public;
        this.notice = res.data.data[0].notice;
        console.log(this.frais_gestion, this.ind_repas, this.taux_charge);
      });
    },
    // this.fieldname9_1 = function(){var total = this.nbrejour*this.tjm
    // let v1 = this.totalverse/total*100
    // let v3 = this.fraisgestion/total*100
    // let v2 = 100-v1-v3
    //setPieChartBlock('canvas1', [{label: 'Revenues', value: v1, color: '#1BC02D'},{label: 'Charges', value: v2, color: '#C0631B'},{label: 'Frais de gestion', value: v3, color: '#F5D90E'}]);})()
  },
  mounted() {
    this.getConfiguration();
  },
};
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px !important;
  height: 25px !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkslider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .checkslider {
  background-color: #2196f3;
}

input:focus + .checkslider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .checkslider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded checksliders */
.checkslider.round {
  border-radius: 34px;
}

.checkslider.round:before {
  border-radius: 50%;
}
.apexsection {
  margin-left: 20%;
}
.chartsection {
  text-align: center;
  align-items: center;
}
.noticesection {
  text-align: center;
  padding: 20px;
}

.codepeoplecalculatedfield,
.simul {
  text-align: right;
}

.styled {
  background-color: #00aaa8 !important;
  font-weight: bold !important;
  color: #fff;
  border-radius: 15%;
}
label {
  font-family: sans-serif !important;
}
/* #chart {
  align-items: center;
  margin-left: 25%;
} */
</style>
