<template>
  <div>
    <section
      id="breadcrumb-section"
      class="breadcrumb-area breadcrumb-center"
      style="
        background: url(https://www.portalite.fr/wp-content/themes/cozipress-pro/assets/images/bg/breadcrumbg.jpg)
          center center scroll;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-content">
              <div class="breadcrumb-heading"></div>
              <ol class="breadcrumb-list">
                <li>
                  <router-link to="/"><i class="fa fa-home"></i></router-link>
                </li>
                <li>Nos services</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="content" class="cozipress-content">
      <Service />
      <!--===// Start: Footer
    =================================-->
    </div>
  </div>
</template>
<script>
import Service from "../components/Service.vue";
export default {
  components: { Service },
};
</script>

<style Service></style>
